import { DecisionMaker, QuoteType } from '~/services/api.types';
import { strings } from './strings';

export const decisionMakerTypes = [
  { value: DecisionMaker.Local, name: 'Local' },
  { value: DecisionMaker.Central, name: 'Central' }
];

export const quoteTypes = [
  {
    value: QuoteType.GeneralWithoutInfiltration,
    name: strings.Devis_général_curatif_sans_infiltration
  },
  {
    value: QuoteType.GeneralInfiltration,
    name: strings.Devis_général_curatif_avec_infiltration
  },
  { value: QuoteType.Diag, name: strings.Devis_diag },
  { value: QuoteType.CeType, name: strings.Devis_type_ce },
  { value: QuoteType.Ce, name: strings.CE },
  {
    value: QuoteType.UrgentInfiltration,
    name: strings.Devis_urgence_infiltrations
  },
  {
    value: QuoteType.SupplementaryCe,
    name: strings.Devis_suite_CE
  },
  {
    value: QuoteType.MinorWorks,
    name: strings.Menu_travaux_suite_CE
  }
];

export const interventionTypes = {
  [QuoteType.GeneralWithoutInfiltration]: strings.Général_sans_infiltration,
  [QuoteType.GeneralInfiltration]: strings.Général_avec_infiltration,
  [QuoteType.Diag]: strings.Diag,
  [QuoteType.CeType]: strings.Type_ce,
  [QuoteType.Ce]: strings.CE,
  [QuoteType.UrgentInfiltration]: strings.Urgence_infiltrations,
  [QuoteType.MinorWorks]: strings.Menu_travaux_suite_CE,
  [QuoteType.SupplementaryCe]: strings.Intervention_suite_CE
};

export const quoteCETypes = [
  { value: QuoteType.CeType, name: strings.Devis_type_ce },
  { value: QuoteType.Ce, name: strings.CE }
];

export const interventionsTableHeaderData = [
  { code: 1, title: strings.contacts },
  { code: 2, title: strings.date_de_demande },
  { code: 3, title: strings.objet_et_message },
  { code: 4, title: strings.info_tarif },
  { code: 5, title: strings.commentaire },
  { code: 6, title: strings.contact },
  { code: 7, title: strings.Rendez_vous },
  { code: 8, title: strings.devis },
  { code: 9, title: strings.commande },
  { code: 10, title: strings.Acompte_versé },
  { code: 11, title: strings.début_des_travaux_prévu },
  { code: 12, title: strings.Date_de_réalisation_des_travaux },
  { code: 13, title: strings.rapport_de_visite },
  { code: 14, title: strings.PV_réception },
  { code: 15, title: strings.plan_prévention },
  { code: 16, title: strings.facture },
  { code: 17, title: strings.SAV }
];

export const appointmentTableHeaderData = [
  { code: 'date', title: strings.Date_RDV },
  { code: 'interlocutor', title: strings.Interlocuteur },
  { code: 'customerContact', title: strings.Contact },
  { code: 'subject', title: strings.Objet },
  { code: 'internalMessage', title: strings.Commentaire_interne }
];

export const contactInstanceTableHeaderData = [
  { code: 'date', title: strings.date_contact },
  { code: 'interlocutor', title: strings.Interlocuteur },
  { code: 'customerContact', title: strings.Contact },
  { code: 'subject', title: strings.Objet },
  { code: 'internalMessage', title: strings.Commentaire_interne }
];

export const manageQuotTypeCeTableHeaderData = [
  { code: 'type', title: ' ' },
  { code: 'quoteType', title: `${strings.Types_de_devis}*` },
  { code: 'assignedAgent', title: strings.Assistante_GC_referente },
  { code: 'period', title: strings.Période },
  { code: 'agencyAmount', title: strings.Montant_agence },
  { code: 'aerialBucket', title: strings.Nacelle },
  { code: 'aerialBucketAmount', title: strings.Montant_nacelle },
  {
    code: 'maxWorkDuringMaintenance',
    title: strings.Menus_travaux_Max_lors_du_CE
  },
  { code: 'objet', title: strings.Objet },
  { code: 'message', title: strings.Message },
  { code: 'internalMessage', title: strings.Commentaire_interne },
  { code: 'comment', title: strings.commentaire },
  { code: 'agence', title: strings.Agence }
];

export const manageCeQuoteTableHeaderData = [
  { code: 'type', title: ' ' },
  { code: 'quoteType', title: `${strings.Types_de_devis}*` },
  { code: 'assignedAgent', title: strings.Assistante_GC_referente },
  { code: 'aerialBucket', title: strings.Nacelle },
  { code: 'objet', title: strings.Objet },
  { code: 'message', title: strings.Message },
  { code: 'internalMessage', title: strings.Commentaire_interne },
  { code: 'comment', title: strings.commentaire },
  { code: 'agence', title: strings.Agence }
];

export const actionstTableHeaderData = [
  { code: 'type', title: strings.Type },
  { code: 'creationDate', title: strings.Date_de_création },
  { code: 'dueDate', title: strings.date_limite },
  { code: 'user', title: strings.Utilisateur },
  { code: 'comment', title: strings.commentaire },
  { code: '', title: '' },
  { code: 'isCompleted', title: strings.terminé },
  { code: 'completionDate', title: strings.date_de_réalisation }
];

export const filterBoxTableHeaderData = [
  { code: '1', title: strings.états },
  { code: '2', title: strings.Types_de_devis },
  { code: '3', title: strings.Contact_RDV },
  { code: '4', title: strings.rapport_de_visite },
  { code: '5', title: strings.devis },
  { code: '6', title: strings.facture },
  { code: '7', title: strings.PV_réception },
  { code: '8', title: strings.accord_commande },
  { code: '9', title: strings.planif_intervention },
  { code: '10', title: strings.relances },
  { code: '11', title: strings.actions_a_faire }
];

export enum SearchBoxData {
  ALL = 'ALL',
  IN_PROCESS = 'IN_PROCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  BILLED = 'BILLED',
  CANCELLED_LOST = 'CANCELLED_LOST',
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  DONE = 'DONE',
  NOT_DONE = 'NOT_DONE',
  EXISTING = 'EXISTING',
  NOT_EXISTING = 'NOT_EXISTING',
  REGISTERED = 'REGISTERED',
  UNREGISTERED = 'UNREGISTERED',
  DATE_FILLED = 'DATE_FILLED',
  DATE_NON_FILLED = 'DATE_NON_FILLED',
  NONE = 'NONE',
  SAV = 'SAV'
}

export const interventionFilePath = {
  FILE: 'file',
  VISIT_REPORT: 'visit-report',
  RECEPTION_RECORD: 'reception-record',
  PREVENTION_PLAN: 'prevention-plan',
  ORDER_FILE: 'order-file'
};

export const quoteDunningsTableHeaderData = [
  { code: 'date', title: strings.Date_relance },
  { code: 'user', title: strings.Utilisateur },
  { code: 'comment', title: strings.Note },
  { code: 'active', title: strings.Actif },
  { code: '', title: '' }
];

export const workTypesFollowingCE = [
  {
    value: QuoteType.MinorWorks,
    name: strings.Menu_travaux_suite_CE
  },
  {
    value: QuoteType.SupplementaryCe,
    name: strings.Intervention_suite_CE
  }
];

export enum generationSource {
  AGENCY = 'AGENCY',
  GC = 'GC'
}

export const workDateHistoryTableHeaderData = [
  { code: 'date', title: strings.Date_travaux },
  { code: 'message', title: strings.Commentaire }
];
