import {
  WorkType,
  ReductionType,
  DepositType,
  Unit,
  InvoicePaymentAuthorizationState
} from '~/services/api.types';
import getEnv from '~/utils/env';
import { strings } from './strings';

export const globalDefaultVatRate =
  Number(getEnv('DEFAULT_GLOBAL_VAT_RATE')) || 20; // TVA par défaut 20%
export const defaultAgencyMarginRate =
  Number(getEnv('DEFAULT_AGENCY_MARGIN_RATE')) || 20; // 20% : marge agence fournitures
export const defaultFranchiserMarginRate =
  Number(getEnv('DEFAULT_FRANCHISER_MARGIN_RATE')) || 8; // 8%: marge franchiseur fournitures
export const defaultGcMarginRateForDiag =
  Number(getEnv('DEFAULT_GC_MARGIN_RATE_FOR_DIAG')) || 25; // 25%: taux de marque GC Devis DIAG
export const defaultGcMarginRateForUrgentInfiltration =
  Number(getEnv('DEFAULT_GC_MARGIN_RATE_FOR_URGENT_INFILTRATION')) || 25; // 25%: taux de marque GC Devis urgence infiltration
export const defaultGcMarginRateForCe =
  Number(getEnv('DEFAULT_GC_MARGIN_RATE_FOR_CE')) || 25; // 25%: taux de marque GC Devis CE
export const defaultGcMarginRateForQuoteTypeCe =
  Number(getEnv('DEFAULT_GC_MARGIN_RATE_FOR_QUOTE_TYPE_CE')) || 20; // 20%: taux de marque GC Devis type CE
export const defaultGcMarginRateForGeneralQuote = [
  {
    minQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FIRST_RANGE_MIN')
      ) || 1,
    maxQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FIRST_RANGE_MAX')
      ) || 1200,
    marginRate:
      Number(
        getEnv(
          'DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FIRST_RANGE_MARGIN_RATE'
        )
      ) || 33
  },
  {
    minQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_SECOND_RANGE_MIN')
      ) || 1201,
    maxQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_SECOND_RANGE_MAX')
      ) || 2500,
    marginRate:
      Number(
        getEnv(
          'DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_SECOND_RANGE_MARGIN_RATE'
        )
      ) || 28
  },
  {
    minQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_THIRD_RANGE_MIN')
      ) || 2501,
    maxQuotePrice: 5000,
    marginRate:
      Number(
        getEnv(
          'DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_THIRD_RANGE_MARGIN_RATE'
        )
      ) || 27
  },
  {
    minQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FOURTH_RANGE_MIN')
      ) || 5001,
    maxQuotePrice: 7500,
    marginRate:
      Number(
        getEnv(
          'DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FOURTH_RANGE_MARGIN_RATE'
        )
      ) || 23
  },
  {
    minQuotePrice:
      Number(
        getEnv('DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FIVETH_RANGE_MIN')
      ) || 7501,
    maxQuotePrice: 9e10,
    marginRate:
      Number(
        getEnv(
          'DEFAULT_GC_MARGIN_RATE_FOR_GENERAL_QUOTE_FIVETH_RANGE_MARGIN_RATE'
        )
      ) || 20
  }
]; // montant du devis en euro et la marge correspondante

export const quoteGeneralInformations1TableHeaderData = [
  { code: 1, title: `${strings.Adresse_du_chantier}*` },
  { code: 2, title: `${strings.Ville}*` },
  { code: 3, title: `${strings.Code_postal}*` },
  { code: 4, title: strings.Pays }
];

export const quoteGeneralInformations2TableHeaderData = [
  { code: 1, title: strings.Dénomination_du_chantier },
  { code: 2, title: `${strings.Nature_des_travaux}*` },
  { code: 3, title: `${strings.Type_de_travaux}*` },
  { code: 4, title: `${strings.Taux_de_TVA}*` },
  { code: 5, title: `${strings.Cout_horaire}*` },
  { code: 6, title: strings.Montant_de_la_remise }
];

export const quoteGeneralInformations3TableHeaderData = [
  { code: 1, title: strings.Champ_libre },
  { code: 2, title: strings.Champ_avertissement },
  { code: 3, title: strings.Acompte_de_prévu },
  { code: 4, title: strings.Montant_génération_du_devis },
  { code: 5, title: strings.Grand_compte }
];

export const invoiceGeneralInformations3TableHeaderData = [
  { code: 1, title: strings.Champ_libre },
  { code: 2, title: strings.Champ_avertissement },
  { code: 4, title: strings.Montant_génération_du_devis },
  { code: 5, title: strings.Grand_compte }
];

// row before General Informations 3 (row 3)
export const invoiceExclusifGeneralInfosTableHeaderData = [
  { code: 2, title: `${strings.Date_de_règlement}*` },
  { code: 3, title: `${strings.Mode_de_règlement}*` },
  { code: 4, title: strings.N_de_commande },
  { code: 6, title: strings.Acompte },
  { code: 7, title: strings.Référence_au_devis }
];

// row before General Informations 3 (row 3)
export const invoiceGcExclusifGeneralInfosTableHeaderData = [
  { code: 1, title: `${strings.Date_de_règlement}*` },
  { code: 2, title: `${strings.Mode_de_règlement}*` },
  { code: 3, title: strings.Suivi_paiement },
  { code: 4, title: strings.paiement_agence },
  { code: 5, title: strings.N_de_commande },
  { code: 6, title: strings.Acompte },
  { code: 7, title: strings.Référence_au_devis }
];

export const roofingWorkTypes = [
  { value: WorkType.Diagnostic, name: strings.Diagnostic },
  { value: WorkType.Maintenance, name: strings.Entretien },
  { value: WorkType.Improvements, name: strings.Améliorations },
  { value: WorkType.Repair, name: strings.Réparations },
  { value: WorkType.ProtectiveMeasures, name: strings.Mesures_conservatoires }
];

export const reductionTypes = [
  { value: ReductionType.Fixed, name: '€' },
  { value: ReductionType.Percent, name: '%' }
];

export const depositTypes = [
  { value: DepositType.Fixed, name: '€' },
  { value: DepositType.Percent, name: '%' }
];

export const quoteOperationsWorkStationsTableHeaderData = [
  { code: 1, title: `${strings.Poste_de_travail}*` },
  { code: 2, title: strings.Quantité },
  { code: 3, title: `${strings.Unité}*` },
  { code: 4, title: strings.Prix_Unitaire },
  { code: 5, title: strings.Totaux }
];

export const quoteUnitTypes = [
  { value: Unit.Ml, name: 'ml' },
  { value: Unit.MSquare, name: 'm²' },
  { value: Unit.MCube, name: 'm³' },
  { value: Unit.U, name: 'U' },
  { value: Unit.Ens, name: 'ens' },
  { value: Unit.Tonne, name: 'tonne' },
  { value: Unit.Package, name: 'Forfait' },
  { value: Unit.L, name: 'Litre' },
  { value: Unit.Kg, name: 'kg' },
  { value: Unit.Day, name: 'Jour' },
  { value: Unit.Hour, name: 'Heure' },
  { value: Unit.Percent, name: '%' },
  { value: Unit.Are, name: 'are' }
];

export const quoteOperationsSuppliesTableHeaderData = [
  { code: 0, title: strings.Quantité_totale },
  { code: 1, title: strings.Multipliable },
  { code: 2, title: strings.Type },
  { code: 3, title: strings.Unité },
  { code: 4, title: strings.Quantité },
  { code: 5, title: strings.Quantité_perdu },
  { code: 6, title: strings.Marge },
  { code: 7, title: strings.Marge_franchiseur },
  { code: 8, title: strings.Prix_achat },
  { code: 9, title: strings.Prix_vente },
  {
    code: 10,
    title: `${strings.Prix_achat} | ${strings.Prix_vente}\n(${strings.Total})`
  },
  { code: 11, title: strings.Total }
];

export const quoteTitleOperationTotalTableHeaderData = [
  { code: 1, title: strings.Total_titre },
  { code: 2, title: strings.Cout_total_des_fournitures_du_titre },
  { code: 3, title: strings.Temps_total_du_titre }
];

export const depositDirectAmountTableHeaderData = [
  { code: 1, title: strings.Description },
  { code: 2, title: strings.Montant }
];

export const invoiceTypes = [
  { value: 'FA', name: 'FA' },
  { value: 'AV', name: 'AV' }
];

export const invoicePaymentAuthorizationTypes = [
  {
    value: InvoicePaymentAuthorizationState.Authorized,
    name: strings.Autorisé
  },
  {
    value: InvoicePaymentAuthorizationState.Unauthorized,
    name: strings.Non_autorisé
  }
];

export const ATTILA_bank_BIC = getEnv('ATTILA_BANK_BIC') || '';
export const ATTILA_bank_IBAN = getEnv('ATTILA_BANK_IBAN') || '';
export const RECOVERY_COST_IN_EURO =
  Number(getEnv('RECOVERY_COST_IN_EURO')) || 40;
// frais de recouvrement 40 euro

export const invoiceAction = { CREATION: 'CREATION' };

export const opsError = {
  ACCOUNTING: 'ACCOUNTING',
  UNIT: 'UNIT',
  DESCRIPTION: 'DESCRIPTION',
  TITLE: 'TITLE',
  AMOUNT_PRESENT: 'AMOUNT_PRESENT',
  ACTIVE_EDIT: 'ACTIVE_EDIT',
  FACTORING: 'FACTORING'
};

export const grpError = (opsGroupId: string | number) =>
  `GRP_OPS_ID-${opsGroupId}`;
export const grpAndOpsError = (opsGroupId: string | number) =>
  `GRP_OPS_ID-${opsGroupId}-OPS_ID-`;

export const getOpsAccountingError = (opsGroupId: string | number) => {
  return `${grpError(opsGroupId)}_ERROR_${opsError.ACCOUNTING}`;
};

export const getOpsTitleError = (opsGroupId: string | number) => {
  return `${grpError(opsGroupId)}_ERROR_${opsError.TITLE}`;
};

export const getOpsActiveEditError = (opsGroupId: string | number) => {
  return `${grpError(opsGroupId)}_ERROR_${opsError.ACTIVE_EDIT}`;
};

export const getOpsUnitError = (
  opsGroupId: string | number,
  opsId: string | number
) => {
  return `${grpAndOpsError(opsGroupId)}${opsId}_ERROR_${opsError.UNIT}`;
};

export const getOpsAmountPresentError = (
  opsGroupId: string | number,
  opsId: string | number
) => {
  return `${grpAndOpsError(opsGroupId)}${opsId}_ERROR_${
    opsError.AMOUNT_PRESENT
  }`;
};

export const getOpsDescriptionError = (
  opsGroupId: string | number,
  opsId: string | number
) => {
  return `${grpAndOpsError(opsGroupId)}${opsId}_ERROR_${opsError.DESCRIPTION}`;
};

export const getOpsFactoringError = (
  opsGroupId: string | number,
  opsId: string | number
) => {
  return `${grpAndOpsError(opsGroupId)}${opsId}_ERROR_${opsError.FACTORING}`;
};
