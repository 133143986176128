import { EventsLogType } from '~/services/api.types';
import { strings } from './strings';

export const eventTypes = [
  {
    value: EventsLogType.AgencyDepositPaymentAuthorizationEvent,
    name: strings.Autorisation_de_paiement_du_dépôt_de_l_agence
  },
  {
    value: EventsLogType.AgencyDepositUnlockedEvent,
    name: `${strings.Déverrouiller} ${strings.acompte_agence}`
  },
  {
    value: EventsLogType.AgencyDepositValidatedEvent,
    name: `${strings.Valider} ${strings.acompte_agence}`
  },
  {
    value: EventsLogType.AgencyInvoicePaymentAuthorizationEvent,
    name: `${strings.Autoriser} ${strings.le} ${strings.paiement_agence}`
  },
  {
    value: EventsLogType.AgencyInvoiceUnlockedEvent,
    name: `${strings.Déverrouiller} ${strings.facture_agence}`
  },
  {
    value: EventsLogType.AgencyInvoiceValidatedEvent,
    name: `${strings.Valider} ${strings.facture_agence}`
  },
  {
    value: EventsLogType.AgencyQuoteUnlockedEvent,
    name: `${strings.Déverrouiller} ${strings.devis_agence}`
  },
  {
    value: EventsLogType.AgencyVisitReportUnlockedEvent,
    name: `${strings.Déverrouiller} ${strings.rapport_de_visite_agence}`
  },
  {
    value: EventsLogType.GcCreditNoteCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.avoir_gc}`
  },
  {
    value: EventsLogType.GcCreditNoteUpdatedEvent,
    name: `${strings.Mise_a_jour} ${strings.avoir_gc}`
  },
  {
    value: EventsLogType.GcCreditNoteValidatedEvent,
    name: `${strings.Valider} ${strings.un} ${strings.avoir_gc}`
  },
  {
    value: EventsLogType.GcDepositCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.acompte_gc}`
  },
  {
    value: EventsLogType.GcDepositCustomerPaymentInstallmentAddedEvent,
    name: `${strings.Paiement_client} ${strings.acompte_gc}`
  },
  {
    value: EventsLogType.GcDepositUpdatedEvent,
    name: `${strings.Mise_a_jour} ${strings.acompte_gc}`
  },
  {
    value: EventsLogType.GcDepositValidatedEvent,
    name: `${strings.Valider} ${strings.un} ${strings.acompte_gc}`
  },
  {
    value: EventsLogType.GcInvoiceCreatedEvent,
    name: `${strings.Creer} ${strings.une} ${strings.facture_gc}`
  },
  {
    value: EventsLogType.GcInvoiceCustomerPaymentInstallmentAddedEvent,
    name: `${strings.Paiement_client} ${strings.facture_gc}`
  },
  {
    value: EventsLogType.GcInvoiceUpdatedEvent,
    name: `${strings.Mise_a_jour} ${strings.facture_gc}`
  },
  {
    value: EventsLogType.GcInvoiceValidatedEvent,
    name: `${strings.Valider} ${strings.une} ${strings.facture_gc}`
  },
  {
    value: EventsLogType.GcQuoteCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.devis_gc}`
  },
  {
    value: EventsLogType.GcQuoteUnlockedEvent,
    name: `${strings.Déverrouiller} ${strings.devis_gc}`
  },
  {
    value: EventsLogType.GcQuoteUpdatedEvent,
    name: `${strings.Mise_a_jour} ${strings.devis_gc}`
  },
  {
    value: EventsLogType.GcQuoteValidatedEvent,
    name: `${strings.Valider} ${strings.un} ${strings.devis_gc}`
  },
  {
    value: EventsLogType.GcVisitReportCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.rapport_de_visite_gc}`
  },
  {
    value: EventsLogType.GcVisitReportUpdatedEvent,
    name: `${strings.Mise_a_jour} ${strings.rapport_de_visite_gc}`
  },
  {
    value: EventsLogType.GcVisitReportValidatedEvent,
    name: `${strings.Valider} ${strings.un} ${strings.rapport_de_visite_gc}`
  },
  {
    value: EventsLogType.OrderCreatedEvent,
    name: `${strings.Creer} ${strings.une} ${strings.commande}`
  },
  {
    value: EventsLogType.OrderDeletedEvent,
    name: `${strings.Supprimer} ${strings.une} ${strings.commande}`
  },
  {
    value: EventsLogType.OrderUpdatedEvent,
    name: `${strings.Mise_a_jour} ${strings.commande}`
  },
  {
    value: EventsLogType.InterventionAgencyAssignedEvent,
    name: `${strings.Assigner} ${strings.une} ${strings.intervention} ${strings.a_une_agence}`
  },
  {
    value: EventsLogType.InterventionCreatedEvent,
    name: `${strings.Creer} ${strings.une} ${strings.intervention}`
  },
  {
    value: EventsLogType.InterventionDeletedEvent,
    name: `${strings.Supprimer} ${strings.une} ${strings.intervention}`
  },
  {
    value: EventsLogType.InterventionLostEvent,
    name: `${strings.Marquer} ${strings.une} ${strings.intervention} ${strings.comme_perdue}`
  },
  {
    value: EventsLogType.InterventionPreventionPlanAddedEvent,
    name: `${strings.Ajouter} ${strings.un} ${strings.plan_prévention}`
  },
  {
    value: EventsLogType.InterventionReceptionRecordAddedEvent,
    name: `${strings.Ajouter} ${strings.un} ${strings.PV_réception}`
  },
  {
    value: EventsLogType.InterventionRestoredEvent,
    name: `${strings.Restaurer} ${strings.une} ${strings.intervention}`
  },
  {
    value: EventsLogType.InterventionUpdatedEvent,
    name: `${strings.Mettre_a_jour} ${strings.une} ${strings.intervention}`
  },
  {
    value: EventsLogType.BuildingCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.batiment}`
  },
  {
    value: EventsLogType.InterventionFileAddedEvent,
    name: `${strings.Ajouter} ${strings.des_fichiers_a_une_intervention}`
  },
  {
    value: EventsLogType.SavCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.SAV}`
  },
  {
    value: EventsLogType.InterventionFileRemovedEvent,
    name: `${strings.Supprimer} ${strings.des_fichiers_d_une_intervention}`
  },
  {
    value: EventsLogType.InterventionReceptionRecordRemovedEvent,
    name: `${strings.Supprimer} ${strings.un} ${strings.PV_réception}`
  },
  {
    value: EventsLogType.InterventionPreventionPlanRemovedEvent,
    name: `${strings.Supprimer} ${strings.plan_prévention}`
  },
  {
    value: EventsLogType.SavFileAddedEvent,
    name: `${strings.Ajouter} ${strings.des_fichiers_a_un_SAV}`
  },
  {
    value: EventsLogType.SavFileRemovedEvent,
    name: `${strings.Supprimer} ${strings.des_fichiers_d_un_SAV}`
  },
  {
    value: EventsLogType.SavReceptionRecordFileAddedEvent,
    name: `${strings.Ajouter} ${strings.SAV} ${strings.PV_réception}`
  },
  {
    value: EventsLogType.SavReceptionRecordFileRemovedEvent,
    name: `${strings.Supprimer} ${strings.SAV} ${strings.PV_réception}`
  },
  {
    value: EventsLogType.SavWorkStartDateAddedEvent,
    name: `${strings.Ajouter} ${strings.SAV} ${strings.Date_debut_travaux}`
  },
  {
    value: EventsLogType.SavRemovedEvent,
    name: `${strings.Supprimer} ${strings.SAV}`
  },
  {
    value: EventsLogType.BuildingUpdatedEvent,
    name: `${strings.Mettre_a_jour} ${strings.un} ${strings.batiment}`
  },
  {
    value: EventsLogType.BuildingDeletedEvent,
    name: `${strings.Supprimer} ${strings.un} ${strings.batiment}`
  },
  {
    value: EventsLogType.CustomerCreatedEvent,
    name: `${strings.Creer} ${strings.un} ${strings.client}`
  },
  {
    value: EventsLogType.CustomerUpdatedEvent,
    name: `${strings.Mettre_a_jour} ${strings.un} ${strings.client}`
  },
  {
    value: EventsLogType.CustomerDisabledEvent,
    name: `${strings.Supprimer} ${strings.un} ${strings.client}`
  },
  {
    value: EventsLogType.CustomerRestoredEvent,
    name: `${strings.Restaurer} ${strings.un} ${strings.client}`
  },
  {
    value: EventsLogType.GcInvoicePdfUpdatedEvent,
    name: `${strings.Mettre_a_jour} ${strings.Pdf} ${strings.facture_gc}`
  },
  {
    value: EventsLogType.GcCreditNotePdfUpdatedEvent,
    name: `${strings.Mettre_a_jour} ${strings.Pdf} ${strings.avoir_gc}`
  },
  {
    value: EventsLogType.GcDepositPdfUpdatedEvent,
    name: `${strings.Mettre_a_jour} ${strings.Pdf} ${strings.acompte_gc}`
  },
  {
    value: EventsLogType.GcVisitReportUnlockedEvent,
    name: `${strings.Déverrouiller} ${strings.rapport_de_visite_gc}`
  },
  {
    value: EventsLogType.GcInvoiceLostEvent,
    name: `${strings.Marquer} ${strings.une} ${strings.facture_gc} ${strings.comme_perdue}`
  },
  {
    value: EventsLogType.GcDepositLostEvent,
    name: `${strings.Marquer} ${strings.un} ${strings.acompte_gc} ${strings.comme_perdu}`
  },
  {
    value: EventsLogType.GcCreditNoteLostEvent,
    name: `${strings.Marquer} ${strings.un} ${strings.avoir_gc} ${strings.comme_perdu}`
  },
  {
    value: EventsLogType.OrderFileDeletedEvent,
    name: `${strings.Supprimer} ${strings.un} ${strings.Fichier} ${strings.commande}`
  },
  {
    value: EventsLogType.OrderFileAddedEvent,
    name: `${strings.Ajouter} ${strings.un} ${strings.Fichier} ${strings.commande}`
  },
  {
    value: EventsLogType.GcInvoiceRestoreEvent,
    name: `${strings.Restaurer} ${strings.une} ${strings.facture_gc}`
  },
  {
    value: EventsLogType.GcDepositRestoreEvent,
    name: `${strings.Restaurer} ${strings.un} ${strings.acompte_gc}`
  },
  {
    value: EventsLogType.GcCreditNoteRestoreEvent,
    name: `${strings.Restaurer} ${strings.un} ${strings.avoir_gc}`
  }
];

export const agentsEventslogtTableHeaderData = [
  { code: 'type', title: strings.Type },
  { code: 'client', title: strings.Client },
  { code: 'agent', title: strings.Agent_GC },
  { code: 'occurredOn', title: strings.Survenu_le },
  { code: 'reference', title: strings.numero },
  { code: 'interventionReference', title: strings.intervention }
];

export const filterBoxEventLogTypesTableHeaderData = [
  {
    code: strings.Devis,
    title: strings.Devis
  },
  {
    code: strings.Facture,
    title: strings.Facture
  },
  {
    code: strings.Acompte,
    title: strings.Acompte
  },
  {
    code: strings.Avoir,
    title: strings.Avoir
  },
  {
    code: strings.rapport_de_visite,
    title: strings.rapport_de_visite
  },
  {
    code: 'other',
    title: ''
  }
];
